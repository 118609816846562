import React from 'react';

const Signin1 = React.lazy(() => import('./Demo/Authentication/SignIn/SignIn1'));
const resetPassword = React.lazy(() => import('./Demo/Authentication/resetPassword/resetPassword'));

const route = [
    { path: '/', exact: true, name: 'Login', component: Signin1 },
    { path: '/Login', exact: true, name: 'Login', component: Signin1 },
    { path: '/reset-password', exact: true, name: 'Reset Password', component: resetPassword },
    
];

export default route;